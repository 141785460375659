import { addClass } from "../utils"

import "./index.scss"

export const footerEl = ({ socialLinks = [], leftLinks = [] }) => {
  const footer = document.createElement("footer")
  
  addClass(footer, "footer")
  footer.innerHTML = `
    <article class="footer__upper">
      <img src="https://cdn.dev.direct.firstam.com/default/assets/global-nav/footer-logo.svg" class="footer__logo" alt="footer-logo" />
      <div class="footer__social-media footer__social-media--desktop">
        ${socialLinks
          .map(
            ({ iconUrl, alt, url }) => `
              <a class="footer__social-media-link" href="${url}">
                <img src="${iconUrl}" alt="${alt}" />
              </a>
        `
          )
          .join("")}
      </div>
    </article>
    <article class="footer__middle">
      ${leftLinks
        .map(
          ({ text, url, newWindow }) => {
          return `
            <a class="footer__link" ${newWindow? 'target="_blank"': ''} href="${url}" id='${text ? "footer_" + (text).replace(/ /g, '').toLowerCase() + "_id" : ""}'>
              <span class="footer__link-border">${text}</span>
            </a>
          `
          }
        )
        .join("")}
    </article>
    <p class="footer__tech-support">
      First American Support<br>
      <a href="mailto:IgniteRE@firstam.com">IgniteRE@firstam.com</a><br>
      (866) 540-5614
    </p>
    <article class="footer__lower">
      <div class="footer__social-media footer__social-media--mobile">
        ${socialLinks
          .map(
            ({ iconUrl, alt, url }) => `
              <a class="footer__social-media-link" href="${url}">
                <img src="${iconUrl}" alt="${alt}" />
              </a>
            `
          )
          .join("")}
      </div>
      <p class="footer__fine-print">&copy;${new Date().getFullYear()} First American Financial Corporation and/or its affiliates. All rights reserved.</p>
    </article>
    <p id="footer-disclaimer" class="footer__fine-print">First American Title Insurance Corporation makes no express or implied warranty respecting the information presented and assumes no responsibility for errors or omissions. First American, the eagle logo, and First American Title are registered trademarks or trademarks of First American Financial Corporation and/or its affiliates.</p>
  `
  return footer
}
